class RevManifest {

    /**
     * @param {Record<string, string>} manifest
     */
    constructor(manifest) {
        /** @type {Record<string, string>} */
        this.manifest = manifest || {};
    }

    /**
     * Accepts a URL string possibly has a match from `/rev-manifest.json`.
     * If a match isn't found, then it will use the given URL as the returned value.
     * @param {string} url
     */
    getURL(url) {
        return this.manifest[url] || url;
    }

}
