var PhaserNS = PhaserNS || {};
var fontReady = false;

PhaserNS.Preloader = function(game) {
    this.retryFiles = [];
    this.retryCounter = 0;
};

PhaserNS.Preloader.prototype = {
    preload: function() {
        console.log('preloader, preload');

        const { revManifest } = this.game.parlay;

        // Since we can't tap into the preloader queue we need to start the phaser preloader after the WebFont lib loads
        // its fonts.
        let runPreloader = () => {
            // window.devicePixelRatio
            let urlPath = 'img/' + this.game.parlay.launchData.BSTYLE + '/' + this.game.parlay.viewType + '/' + this.game.parlay.viewDpi + 'x/';
            let td = this.game.parlay.theme.find('Container');

            document.body.style.backgroundColor = td.prop('backgroundColor', "transparent");
            document.body.style.backgroundImage = td.prop('backgroundImage', "transparent");
            document.body.style.backgroundPosition = td.prop('backgroundPosition', '0% 0%');
            document.body.style.backgroundSize = td.prop('backgroundSize', 'auto');
            document.body.style.backgroundRepeat = td.prop('backgroundRepeat', 'repeat');


            let tdLoader = this.game.parlay.theme.find('Loader');
            this.stage.backgroundColor = tdLoader.prop('color', '#000');
            this.stage.disableVisibilityChange = true;

            const bgImg = this.add.image(0, 0, 'bg');

            // if portrait view, adjust height to fill in bottom based on device height
            if (this.game.parlay.isCompactView()) {
                bgImg.height = this.game.world.height;
                bgImg.scale.x = bgImg.scale.y;
            }

            let loaderLogo_theme = this.game.parlay.theme.find('LoaderLogo');
            let gfx = this.add.sprite(game.world.centerX + loaderLogo_theme.prop('x', 0), game.world.centerY + loaderLogo_theme.prop('y', 0), 'loader', 'Loading_GFX.png');
            gfx.anchor.setTo(0.5);

            let loaderBar_theme = this.game.parlay.theme.find('LoaderBar');
            let lbOuter = this.add.sprite(game.world.centerX + loaderBar_theme.prop('x', 0), game.world.centerY + loaderBar_theme.prop('y', 0), 'loader', 'LoadingBar_Back.png');
            lbOuter.anchor.setTo(0.5);

            let lb = this.add.sprite((lbOuter.x - lbOuter.width/2) + loaderBar_theme.prop("inner_x", 0), (lbOuter.y - lbOuter.height/2) + loaderBar_theme.prop("inner_y", 0), 'loader', 'LoadingBar_Fill.png');
            // lb.anchor.setTo(0.5);

            this.load.setPreloadSprite(lb);

            let locale = this.game.cache.getJSON('locale');

            let txt = '';
            let size = 100;

            if(locale['preLoader.loading'].msg !== undefined) {
                txt = locale['preLoader.loading'].msg;
                size = locale['preLoader.loading'].size;
            } else {
                txt = locale['preLoader.loading'];
            }

            let loaderTxt_theme = this.game.parlay.theme.find('LoaderTxt');
            let text = game.add.text(
                game.world.centerX + loaderTxt_theme.prop('x', 0),
                game.world.centerY + loaderTxt_theme.prop('y', 0),
                txt, {
                    font            : loaderTxt_theme.prop('font', "90px Dimbo"),
                    fontSize        : 90 * (size / 100),
                    fill            : loaderTxt_theme.prop('fill', "#FFF"),
                    align           : "center",
                    stroke          : loaderTxt_theme.prop('stroke', "#6A160F"),
                    strokeThickness : loaderTxt_theme.prop('strokeThickness', 16)
                });
            text.anchor.set(0.5);

            this.game.add.tween(text.scale)
                .to( {x: 1.1, y: 1.1 }, 500, Phaser.Easing.Quadratic.Out)
                .to( {x: 1, y: 1 }, 500, Phaser.Easing.Quadratic.In)
                .loop()
                .start();

            if(!this.game.parlay.launchData.sideGame) {
                this.load.atlasJSONHash('brand', revManifest.getURL(urlPath + 'brand.png'), revManifest.getURL(urlPath + 'brand.json'));
            }

            this.load.atlasJSONHash('bingo', revManifest.getURL(urlPath + 'bingo.png'), revManifest.getURL(urlPath + 'bingo.json'));

            // compact (portrait) mode chat uses HTML/CSS so load the styles here
            if(this.game.parlay.viewType == 'compact') {
                let linkEl = document.createElement('link');
                linkEl.rel = "stylesheet";
                linkEl.type = "text/css";
                linkEl.href = revManifest.getURL('css/chat.css');
                document.getElementsByTagName('head')[0].appendChild(linkEl);

            } else {
                this.load.atlasJSONHash('chat', revManifest.getURL(urlPath + 'chat.png'), revManifest.getURL(urlPath + 'chat.json'));
            }

            if(this.game.parlay.launchData.TOURNAMENT) {
                this.load.atlasJSONHash('tournament', revManifest.getURL(urlPath + 'tournament.png'), revManifest.getURL(urlPath + 'tournament.json'));
            }

            this.load.audio('bingouisounds', [
                revManifest.getURL('audio/sounds/bingouisounds.mp3'),
                revManifest.getURL('audio/sounds/bingouisounds.ogg')
            ]);
            this.load.json('bingouisounds', revManifest.getURL('audio/sounds/bingouisounds.json'));

            this.load.audio('announcer', [
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.ANNOUNCER + '/announcer.mp3'),
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.ANNOUNCER + '/announcer.ogg')
            ]);
            this.load.json('announcer', revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.ANNOUNCER + '/announcer.json'));

            this.load.audio('75ball', [
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/75ball.mp3'),
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/75ball.ogg')
            ]);
            this.load.json('75ball', revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/75ball.json'));

            this.load.audio('80ball', [
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/80ball.mp3'),
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/80ball.ogg')
            ]);
            this.load.json('80ball', revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/80ball.json'));

            this.load.audio('90ball', [
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/90ball.mp3'),
                revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/90ball.ogg')
            ]);
            this.load.json('90ball', revManifest.getURL('audio/speech/' + this.game.parlay.locale  + '/' + this.game.parlay.launchData.CALLER + '/90ball.json'));

            // ---- JSON configs
            this.load.json('chatEmote', revManifest.getURL('config/chatEmote.json'));

            // ---- Side Games Images
            if(_.isObject(this.game.parlay.siteData) && this.game.parlay.siteData.sideGamesAssetsURL) {
                _.each(this.game.parlay.sideGamesData, (sideGame) => {
                    this.load.image(sideGame.type + sideGame.configId, this.game.parlay.siteData.sideGamesAssetsURL + '/' + sideGame.type.toUpperCase() + '/' + sideGame.style.toUpperCase() + '/img/icons/standard.jpg');
                });
            }

            this.load.onLoadComplete.add(() => {
                if(this.retryFiles.length > 0) {
                    if(this.retryCounter > 3) {
                        throw Error("Retry limit for downloads exceeded");
                    }

                    // setTimeout is neccessary to break Phaser's loader finishedLoading reset (happens AFTER onLoadComplete dispatch...)
                    setTimeout(() => {
                        this.load.reset();
                        let urlPath = 'img/standard/' + this.game.parlay.viewType + '/' + this.game.parlay.viewDpi + 'x/';

                        _.each(this.retryFiles, (fileData) => {
                            console.log('downloading standard theme files for ', fileData.key, fileData);

                            let splitUrl = fileData.data.url.split("/");
                            let fileName = splitUrl[splitUrl.length - 1];
                            let fileNameParts = fileName.split('.');
                            this.load.atlasJSONHash(fileData.key, revManifest.getURL(urlPath + fileNameParts[0] + '.png'), revManifest.getURL(urlPath + fileNameParts[0] + '.json'));

                        });

                        this.retryFiles = [];
                        this.retryCounter++;
                        this.load.start();
                    }, 10);

                } else {
                    this._create();
                }
            });

            // If no files found to work with given BSTYLE then use standard skin (for atlases)
            this.load.onFileError.add((fileKey, file) => {
                if(file.type === 'textureatlas') {
                    this.retryFiles.push({key: fileKey, data: file});
                }
            });

            this.load.start();
        };

        let fontFamilyNames = this.game.parlay.theme.find('Font_Files').prop('families');
        let fontFamilyUrls = this.game.parlay.theme.find('Font_Files').prop('urls');

        if(fontFamilyNames && fontFamilyUrls) {
            console.log('WebFont.load: running', fontFamilyNames, fontFamilyUrls);
            WebFont.load({
                custom: {
                    families: fontFamilyNames,
                    urls: [revManifest.getURL('img/' + this.game.parlay.launchData.BSTYLE + fontFamilyUrls)]
                },
                active: () => {
                    console.log('WebFont.load: active!');
                    runPreloader();
                }
            });

        } else {
            console.log('WebFont.load: skip');
            runPreloader();
        }
    },

    // not the real phaser "create" but my own override
    _create: function() {
        console.log('preloader, create');

        // onFileError does not get cleared when moving to next state, can get caught up on audio files like 80ball
        this.load.onLoadComplete.removeAll();
        this.load.onFileError.removeAll();

        this.game.parlay.postPreloader();
        this.game.state.add('game', PhaserNS.Game);
        this.state.start('game', true, false);
    }
};
