/**
 * This is the Theme system for the Parlay Bingo
 * 
 * It should respect theme values as follows (list goes from lowest to highest importance level):
 * 
 * - Default code component options : This is the initial value of the item and has the lowest presedence.  It can be overwritten by anything and is applied when creating the code.
 * - Theme Default Values           : This is something like "Font_Default" where it globally changes something like font family. Always 1-to-many and applied Deep in `BaseComponent` class.
 * - Grouped Theme Values           : Sometimes it's easier to make a theme value to apply to multiple items like bingo card numbers or incr/decr buttons. Usually 1-to-many and uses `createTextProps()`
 * - Named Component Theme Value    : This value is always respected at the highest level. It overwrites any other values and it's always 1-to-1 match
 */
class Theme {
    constructor(core) {
        this.core = core;
        this.themeJson = this.core.game.cache.getJSON('theme');
    }

    /**
     * Used to generate a repeatable key for components that are not named
     * @param component the component to pull the class name from
     */
    getKey(component) {
        return component.constructor.name;
    }
    find(key) {
        return new ThemeProperty(this.themeJson.data[key] !== undefined ? this.themeJson.data[key] : null);
    }
}

class ThemeProperty {
    constructor(data) {
        this.data = data;
    }

    prop(prop, defaultVal = null, isRelative = false) {
        if(this.data && this.data[prop] !== undefined) {
            let data = this.data[prop];

            if(isRelative && defaultVal) {
                data += defaultVal;
            }
            
            return data;

        } else {
            return defaultVal;
        }
    }

    /** Allows for the use of "createText" functions without explicitly naming it.  Is useful if you will have many
     * components (i.e. call board numbers) but don't want hundreds of duplicate theme entries.
     *
      * @param overrides
     * @returns {{font: *, fontSize: *, fill: *, align: *, stroke: *, strokeThickness: *}}
     */
    createTextProps(overrides = {}) {
        let retOverrides = {
            ...overrides
        };

        if(this.data) {
            for (const [key, value] of Object.entries(this.data)) {
                retOverrides[key] = value;
            }
        }

        return retOverrides;
    }
}