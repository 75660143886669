const MarketingModal = ((modal) => {

    const once = (fn) => {

        let hasExecuted = false;

        return () => {
            if(hasExecuted) {
                return;
            }

            fn();
            hasExecuted = true;
        };

    };

    const getAccountStatus = core => new Promise(resolve => {

        const { gameInstance } = core;
        const { data } = gameInstance;

        core.updateBalanceInfo(data.game_number, data.game_sid);
        core.signal_balanceUpdate.addOnce((info) => {

            let cash = 'N/A';
            let bonus = 'N/A';
            let balance = 'N/A';

            if (info) {
                cash = gameInstance.currency(info.player_cash, 'player');
                bonus = gameInstance.currency(info.player_bonus, 'player');
                balance = gameInstance.currency(
                    info.player_cash + info.player_bonus,
                    'player'
                );
            }

            resolve({
                cash,
                bonus,
                balance,
            });
        });

    });

    const getModalPosition = (canvas, button) => {
        const {
            width: canvasWidth,
            height: canvasHeight,
        } = canvas;

        const { innerWidth, innerHeight } = window;

        const {
            width: scaledCanvasWidth,
            height: scaledCanvasHeight,
            x: scaledX,
            y: scaledY,
        } = canvas.getBoundingClientRect();

        const xFactor = scaledCanvasWidth / canvasWidth;
        const yFactor = scaledCanvasHeight / canvasHeight;

        const { centerX, centerY } = button;

        const x = scaledX + (centerX * xFactor);
        const y = scaledY + (centerY * yFactor);

        const left = (x / innerWidth) * 100;
        const top = (y / innerHeight) * 100;

        return {
            top,
            left
        };

    };

    const configureTransition = (top, left) => {
        modal.configure({
            transitionStyle: {
                close: {
                    content: {
                        inset: `${top}% 0 0 ${left}%`,
                        transform: 'scale(0.0125) translate(-0.625%)',
                    },
                },
                common: {
                    content: {
                        transition: 'all 450ms ease-in-out',
                    },
                },
            }
        });
    };

    const initialize = core => {
        const { SESSIONID, ROOMID } = ParlayBingo.queryString();
        const { SITECODE } = core.launchData;

        modal.configure({
            ...core.contentfulCredentials,
            jSessionToken: SESSIONID,
            siteId: SITECODE,
            roomId: ROOMID,
            getAccountStatus: () => getAccountStatus(core),
            locales: [core.locales],
        });
    };

    const showModalOnce = once(modal.open);

    const configure = (canvas, button, autoPopupOnLoad = false) => {

        const fire = (callback) => {
            const { top, left } = getModalPosition(
                canvas,
                button
            );

            configureTransition(top, left);

            callback();
        };


        if (autoPopupOnLoad) {
            fire(showModalOnce);
        }

        button.events.onInputUp.add(() => fire(modal.open));

    };

    return {
        initialize,
        configure,
    };

})(BingoMarketingModal);
