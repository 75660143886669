class i18n {
    constructor(locale, localeTranslationCache) {
        this.localeTranslationCache = localeTranslationCache;

        console.log(`i18n:\tInitialized locale '${locale}'`);
    }

    _t(key, data) {

        if(this.localeTranslationCache === undefined) {
            throw new Error('Translations cache was expected but not found.');
        }

        if(this.localeTranslationCache[key] === undefined) {
            Sentry.captureMessage("l10n: missing translation '" + key + "'", {
                level: 'warning',
                tags: {
                    'locale': this.locale
                }
            });

            return key;

        } else {
            return new i18nTrans(key, this.localeTranslationCache[key], data);
        }
    }
}

class i18nTrans {
    constructor(key, rawTransObj, data) {
        this.key = key;
        this.translation = key;
        this.template = null;
        this.sizeAdjust = 100;  // percentage to offset original value by
        this.xOffset = 0;
        this.yOffset = 0;
        this.data = data;

        let template = (str) => {
            let orig_settings = _.templateSettings;
            _.templateSettings = {
                interpolate: /{{(.+?)}}/
            };

            let t = _.template(str);
            _.templateSettings = orig_settings;

            return t;
        };

        switch (typeof rawTransObj) {
            case "object":
                this.template = template(rawTransObj.msg);
                this.sizeAdjust = rawTransObj.size;

                if (rawTransObj.x_offset !== undefined) {
                    this.xOffset = rawTransObj.x_offset;
                }
                if (rawTransObj.y_offset !== undefined) {
                    this.yOffset = rawTransObj.y_offset;
                }
                break;

            case "string":
                this.template = template(rawTransObj);
                break;
            default:
                throw new Error('i18nTrans: was expecting string or object but found "' + typeof rawTransObj + '" instead');
        }

        this.translation = this.template(data);
    }

    // toString override added to prototype of Foo class
    toString() {
        return this.translation;
    }

    setData(data) {
        this.data = data;
        this.translation = this.template(data);
    }
}