class BaseComponent {
    static get TXT_ANCHOR_LEFT(){return 0;}
    static get TXT_ANCHOR_CENTER(){return 0.5;}
    static get TXT_ANCHOR_RIGHT(){return 1;}

    constructor(gameInstance, x = 0, y = 0, baseGroup = undefined) {
        this.x = x;
        this.y = y;
        this.isDestroyed = false;

        if (gameInstance instanceof GameInstance) {
            this.gameInstance = gameInstance;
            this.game = gameInstance.game;
            this.data = gameInstance.data;

        } else {
            this.gameInstance = null;
            this.game = gameInstance;
            this.data = null;
        }

        // set up access to theme via shortcut reference here for external use
        this.theme = this.game.parlay.theme;

        // gameInstance could be 'game' if no gameInstance has been created yet (early errors)
        if (baseGroup === undefined && this.gameInstance instanceof GameInstance) {
            baseGroup = this.gameInstance.getGameGroup();
        }

        this.group = game.add.group(baseGroup);
        this.group.x = this.x;
        this.group.y = this.y;

        // Track components for redraw later
        this.components = {};

        this.phaserTimers = [];
    }

    draw() {
    }

    destroy() {
        if(!this.isDestroyed) {
            this.group.destroy(true);

            _.each(this.phaserTimers, (t) => {
                this.game.time.events.remove(t);
            });

            this.isDestroyed = true;
        }
    }

    addComponent(name, component) {
        if (typeof this.components[name] !== 'undefined' && this.components[name].exists === true) {
            throw new Error('component with name "' + name + '" already exists');
        } else {
            this.components[name] = component;
        }
    }

    createTimerLoop(ms, callback, ctx = this, kickIt = false) {
        if (kickIt) {
            callback.call(ctx);
        }
        let daLoop = game.time.events.loop(ms, callback, ctx);
        this.phaserTimers.push(daLoop);

        return daLoop;
    }

    createNineSlice(x, y, spriteKey, cornerSize, w, h, addToDefaultGroup = true, spriteAtlas = 'bingo', themeKey = null) {
        let theme = this.theme.find((themeKey) ? themeKey : `${spriteAtlas}__${spriteKey}`);

        x = theme.prop('x', x, true);
        y = theme.prop('y', y, true);
        cornerSize = theme.prop('cornerSize', cornerSize);
        w = theme.prop('w', w, true);
        h = theme.prop('h', h, true);
        let offsets = null;

        if (typeof cornerSize === 'object') {
            offsets = {
                top     : cornerSize.top * BaseComponent.getScaleForDPI(),
                bottom  :cornerSize.bottom * BaseComponent.getScaleForDPI(),
                left    : cornerSize.left * BaseComponent.getScaleForDPI(),
                right   : cornerSize.right * BaseComponent.getScaleForDPI(),
            };

        } else {
            offsets = {
                top     : cornerSize * BaseComponent.getScaleForDPI(),
                bottom  : cornerSize * BaseComponent.getScaleForDPI(),
                left    : cornerSize * BaseComponent.getScaleForDPI(),
                right   : cornerSize * BaseComponent.getScaleForDPI(),
            };
        }

        let box = new PhaserNineSlice.NineSlice(this.game, 
            x * BaseComponent.getScaleForDPI(), 
            y * BaseComponent.getScaleForDPI(), 
            spriteAtlas, 
            spriteKey,
            w * BaseComponent.getScaleForDPI(),
            h * BaseComponent.getScaleForDPI(),
            offsets
        );

        this.game.add.existing(box);

        if (addToDefaultGroup instanceof Object) {
            addToDefaultGroup.add(box);
        } else if (addToDefaultGroup) {
            this.group.add(box);
        }
        return box;
    }

    createGroup(x, y, parentGroup) {
        let grp = game.add.group(parentGroup);
        grp.x = x * BaseComponent.getScaleForDPI();
        grp.y = y * BaseComponent.getScaleForDPI();

        return grp;
    }

    createGraphics(x, y, addToDefaultGroup = true) {
        let gfx = this.game.add.graphics(x * BaseComponent.getScaleForDPI(), y * BaseComponent.getScaleForDPI());

        if (addToDefaultGroup instanceof Object) {
            addToDefaultGroup.add(gfx);
        } else if (addToDefaultGroup) {
            this.group.add(gfx);
        }

        return gfx;
    }

    createSprite(x, y, key, addToDefaultGroup, spriteAtlas, themeKey = null) {
        return this.raw_createSprite(x * BaseComponent.getScaleForDPI(), y * BaseComponent.getScaleForDPI(), key, addToDefaultGroup, spriteAtlas, themeKey);
    }

    raw_createSprite(x, y, key, addToDefaultGroup = true, spriteAtlas = 'bingo', themeKey = null) {
        let theme = this.theme.find((themeKey) ? themeKey : `${spriteAtlas}__${key}`);
        x = theme.prop('x', x, true);
        y = theme.prop('y', y, true);

        let spr = this.game.add.sprite(x, y, spriteAtlas, key);

        if (addToDefaultGroup instanceof Object) {
            addToDefaultGroup.add(spr);

        } else if (addToDefaultGroup) {
            this.group.add(spr);
        }

        return spr;
    }

    createText(name, x, y, text, options, anchor, addToDefaultGroup) {
        return this.raw_createText(name, x * BaseComponent.getScaleForDPI(), y * BaseComponent.getScaleForDPI(), text, options, anchor, addToDefaultGroup);
    }

    raw_createText(name, x, y, text, options = {}, anchor = BaseComponent.TXT_ANCHOR_LEFT, addToDefaultGroup = true) {
        let txtSizeAdjust = 100; // percentage to scale the text by (help fit i18n strings)
        let xOffset = 0;
        let yOffset = 0;
        let isVisible = true;
        let txtShadow = false;

        const compTheme = this.theme.find(name);
        const fontDefaultTheme = this.theme.find("Font_Defaults");

        x = compTheme.prop('x', x, true);
        y = compTheme.prop('y', y, true);

        const getValue = (propName, defaultVal) => {
            if (compTheme.prop(propName) !== null) {
                return compTheme.prop(propName);

            } else if (options[propName] !== undefined && options[propName]) {
                return options[propName];
            } else {
                return defaultVal;
            }
        };

        if (text instanceof i18nTrans) {
            txtSizeAdjust = text.sizeAdjust;

            if (text.xOffset !== undefined) {
                xOffset = text.xOffset;
            }

            if (text.yOffset !== undefined) {
                yOffset = text.yOffset;
            }

            text = text.translation;
        }



        options.font = getValue('font', fontDefaultTheme.prop('font', 'Dimbo'));
        options.fontSize = getValue('fontSize', 22) * BaseComponent.getScaleForDPI() * (txtSizeAdjust / 100);
        options.fontWeight = getValue('fontWeight', fontDefaultTheme.prop('fontWeight', 'normal'));
        options.fill = getValue('fill',  fontDefaultTheme.prop('fill', '#FFF'));
        options.align = getValue('align', fontDefaultTheme.prop('align', 'center'));
        options.stroke = getValue('stroke', fontDefaultTheme.prop('stroke', '#000'));
        options.strokeThickness = getValue('strokeThickness', fontDefaultTheme.prop('strokeThickness', 0));
        // options.wordWrap = getValue('wordWrap', fontDefaultTheme.prop('wordWrap', false));
        // options.wordWrapWidth = getValue('wordWrapWidth', fontDefaultTheme.prop('wordWrapWidth', 100));

        let txt = this.game.add.text(x + xOffset, y + yOffset, text, options);

        // check visibility of txt and respects theme "levels"
        if(options.visible) {
            isVisible = options.visible;
        }

        const compThemeisVisible = compTheme.prop('visible') ;
        if(compThemeisVisible|| compThemeisVisible === false) {
            isVisible = compThemeisVisible;
        }
        txt.visible = isVisible;
        
        // check shadows of txt and respects theme "levels"
        if(options.shadow) {
            txtShadow = options.shadow;
        }
        if(compTheme.prop('shadow')) {
            txtShadow = compTheme.prop('shadow');
        }

        if(txtShadow) {
            txt.setShadow(...txtShadow);
        }

        if(compTheme.prop('lineSpacing')) {
            txt.lineSpacing = compTheme.prop('lineSpacing');
        }

        // ---

        txt.anchor.x = anchor;

        if (name !== null) {
            this.addComponent(name, txt);
        }

        if (addToDefaultGroup instanceof Object) {
            addToDefaultGroup.add(txt);
        } else if (addToDefaultGroup) {
            this.group.add(txt);
        }
        return txt;
    }

    createButton(x, y, sprite, callback, addToDefaultGroup, spriteAtlas, themeKey = null, disablePixelPerfect = false) {
        return this.raw_createButton(x * BaseComponent.getScaleForDPI(), y * BaseComponent.getScaleForDPI(), sprite, callback, addToDefaultGroup, spriteAtlas, themeKey, disablePixelPerfect);
    }

    raw_createButton(x, y, sprite, callback, addToDefaultGroup = true, spriteAtlas = 'bingo', themeKey = null, disablePixelPerfect = false) {
        let theme = this.theme.find((themeKey) ? themeKey : `${spriteAtlas}__${sprite}`);
        x = theme.prop('x', x, true);
        y = theme.prop('y', y, true);

        let btn = this.game.add.button(x, y, spriteAtlas, callback, this, sprite, sprite, sprite);
        btn.name = sprite;

        if(!disablePixelPerfect) {
            btn.input.pixelPerfectOver = true;
            btn.input.pixelPerfectClick = true;
        }
        
        btn.input.useHandCursor = true;

        if (addToDefaultGroup instanceof Object) {
            addToDefaultGroup.add(btn);
        } else if (addToDefaultGroup) {
            this.group.add(btn);
        }
        return btn;
    }

    _t(key, data) {
        return this.gameInstance.i18n._t(key, data);
    }

    static getScaleForDPI(inverse = false) {
        // const maxPixelRatio = 2;
        // var pr = (window.devicePixelRatio <= maxPixelRatio ? window.devicePixelRatio : maxPixelRatio);
        let pr = 2;
        return (inverse) ? (1 / pr) : pr;
    }

    /**
     * This function accepts 'game_parts' from getGame data return.
     * @param {Object} game_parts 
     * @returns array of pattern codes
     */
    static createAllPatternCombos(game_parts) {
        let allPatternCombos = [];

        // Function ensures that any of the 'values' do no exist in 'test'
        let isUnique = (test, values) => {
            for(let t of test) {
                let contains = 0;
                for (let tt of t) {
                    for (let v of values) {
                        if (_.isEqual(v, tt)) {
                            contains++;
                            if (contains === t.length) {
                                return false;
                            }
                        }

                    }
                }
            }
            return true;
        };

        // Tests equality of test and values at a deeper array level
        let isEqual = (test, values) => {
            for(let t of test) {
                for (let v of values) {
                    if (_.isEqual(v, t)) {
                        return true;
                    }
                }
            }
            return false;
        };

        for(let i = 0; i < Object.keys(game_parts).length; i++) {
            let po = game_parts[i];
            let currentPart = [];
            let idx = 0;

            for(let k = 0; k < po.pat_code.length; k++) {
                currentPart.push([po.pat_code[k]]);
            }

            let initialParts = _.clone(currentPart);
            for (let j = 0; j < po.num_matches - 1; j++) {
                let newCurrPart = [];

                for(let z = 0; z < currentPart.length; z++) {
                    for(let y = 0; y < initialParts.length; y++) {
                        if(!isEqual(currentPart[z], initialParts[y]) &&
                            isUnique(newCurrPart, [...currentPart[z], ...initialParts[y]]))
                        {
                            newCurrPart.push([...currentPart[z], ...initialParts[y]]);
                        }
                    }
                }

                currentPart = _.clone(newCurrPart);
            }

            let zipper = [];
            for(let patt of currentPart) {
                let pattTogether = [];
                for(let part of patt) {
                    pattTogether.push(...part);
                }
                zipper.push(pattTogether);
            }

            allPatternCombos[i] = zipper;
        }

        console.log('ALL PATTERN COMBINATIONS', allPatternCombos);
        return allPatternCombos;
    }
}
