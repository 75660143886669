class AutoBuy {
    constructor(core, games, tickets, isAnyPrice, maxTicketPrice, maxLoss) {
        this.core = core;
        
        this.games = games;
        this.tickets = tickets;
        this.isAnyPrice = isAnyPrice;
        this.maxTicketPrice = maxTicketPrice;

        // if `maxLoss == null` that means loss limits are turned off in config
        this.maxLoss = maxLoss;

        this.isActive = false;
        
        this.signalBinding_balanceUpdate = null;
        this.currPlayerBalance = null;

        console.log('AUTOBUY - init', core, games, tickets, isAnyPrice, maxTicketPrice, maxLoss);
    }

    start(currPlayerBalance) {
        console.log('AUTOBUY - start', currPlayerBalance);

        // collect player balance from most recent game call then keep updated via signals
        if(this.maxLoss) {
            this.playerBalanceMaxLossThresh = currPlayerBalance - this.maxLoss;
            this.currPlayerBalance = currPlayerBalance;
        }

        // Hook into signal system
        this.signalBinding_balanceUpdate = this.core.signal_balanceUpdate.add((balInfo) => {
            if(balInfo) {
                this.currPlayerBalance = balInfo.player_cash + balInfo.player_bonus;
                console.log("AUTOBUY - UPDATE - balance signal", this.currPlayerBalance);
            }
        });

        this.core.signal_autoBuy.dispatch({
            "state": "ENABLED",
            "msg": "Auto Buy enabled"
        });

        this.isActive = true;
    }

    stop(message = null) {
        console.log('AUTOBUY - stop');
        this.isActive = false;

        if(this.signalBinding_balanceUpdate) {
            this.signalBinding_balanceUpdate.detach();
        }

        this.core.signal_autoBuy.dispatch({
            "state": "DISABLED",
            "msg": "Auto Buy disabled"
        });

        if(message) {
            this.core.openMessageModal(message, true);
        }
    }

    buyTickets(gameNumber, gameSid, ticketCost, maxTicketsAvailInGame) {
        console.log("AUTOBUY - buyTickets");

        console.log("AUTOBUY - game thresh test",this.games ,this.isAnyPrice, ticketCost, this.maxTicketPrice);
        if(this.games >= 1 && (this.isAnyPrice || ticketCost <= this.maxTicketPrice)) {

            console.log("AUTOBUY - balance thresh test",this.currPlayerBalance ,this.playerBalanceMaxLossThresh, this.currPlayerBalance >= this.playerBalanceMaxLossThresh);
            
            if(this.maxLoss == null || this.currPlayerBalance >= this.playerBalanceMaxLossThresh) {
                const ticksToBuy = (this.tickets > maxTicketsAvailInGame) ? maxTicketsAvailInGame : this.tickets;
                
                this.core.buyTickets(gameNumber, ticksToBuy, gameSid);
                console.log("AUTOBUY - BUY - success!");

            } else {
                this.stop(this.core.i18n._t('autoBuy.maxLossThreshold'));
                console.log("AUTOBUY - OFF - maxLoss thresh hit");
            }

        } else {
            console.log("AUTOBUY - SKIP - card cost over limit..");
        }

        // Always subtract a game from every buy attempt (safest route for now)
        this.games--;

        // Test if we're all done the auto buy round
        if(this.games < 1) {
            this.stop(this.core.i18n._t('autoBuy.gamesPlayedDone'));
            console.log("AUTOBUY - OFF - games played out");
        }
    }
}