var PhaserNS = PhaserNS || {};

PhaserNS.Bootstrap = function(game) {
    this.retryFiles = [];
    this.retryCounter = 0;
};

PhaserNS.Bootstrap.prototype = {
    init: function() {
        this.load.crossOrigin   = 'Anonymous';
        this.load.baseURL       = this.game.parlay.assetURL;
    },

    preload: function() {
        console.log('bootstrap, preload');

        const { revManifest } = this.game.parlay;

        // this replaces `this.load.script('viewType', 'bi.' + this.game.parlay.viewType + '.min.js');` because it's buggy
        let viewTypeScript = document.createElement('script');
        viewTypeScript.src = revManifest.getURL('bi.' + this.game.parlay.viewType + '.min.js');
        document.head.appendChild(viewTypeScript);

        let setPreloadData = () => {

            console.log('this.game.parlay', this.game.parlay.launchData.BSTYLE);
            console.log('this.game.parlay.viewType ', this.game.parlay );

            this.load.json('locale', revManifest.getURL('locales/' + this.game.parlay.locale + '.json'));

            if(this.game.parlay.locale !== 'en') {
                this.load.json('locale_base', revManifest.getURL('locales/en.json'));
            }

            this.load.json('theme', revManifest.getURL('img/' + this.game.parlay.launchData.BSTYLE + '/' + this.game.parlay.viewType + '/theme.json'));

            const urlPath = 'img/' + this.game.parlay.launchData.BSTYLE + '/' + this.game.parlay.viewType + '/'+ this.game.parlay.viewDpi + 'x/';
            this.load.image('bg', revManifest.getURL(urlPath + 'background.jpg'));
            this.load.atlasJSONHash('loader', revManifest.getURL(urlPath + 'loader.png'), revManifest.getURL(urlPath + 'loader.json'));

            // If no files found to work with given BSTYLE then use standard skin
            this.load.onFileError.add((fileKey, file) => {

                if(fileKey === 'theme') {
                    console.error("preload -> onError -> theme: Error: ", file.errorMessage);

                    if(this.game.parlay.launchData.BSTYLE !== 'standard') {
                        console.log('preload -> onError -> theme: setting BSTYLE to standard and trying again...');
                        this.load.reset(true);

                        this.game.parlay.launchData.BSTYLE = 'standard';
                        setPreloadData();

                    } else {
                        throw new Error("preload -> onError -> theme: Could not find standard skin!");
                    }

                } else if(fileKey === 'locale') {
                    console.error("preload -> onError -> locale: Error: ", file.errorMessage);

                    if (this.game.parlay.locale !== 'en') {
                        this.load.reset(true);

                        Sentry.captureMessage("There were no files found for LOCALE '" + this.game.parlay.locale + "'.  Defaulting to 'en'", {
                            level: 'warning'
                        });

                        this.game.parlay.locale = 'en';
                        setPreloadData();

                    } else {
                        throw new Error("Could not find locale 'en'!");
                    }

                } else if(file.type === 'textureatlas' || file.type === 'image') {
                    console.error("preload -> onError -> textureatlas / img: Error: ", file.errorMessage);
                    this.retryFiles.push({key: fileKey, data: file});

                } else if(file.error){
                    console.error("preload -> onError -> Error: ", file.errorMessage);
                    throw new Error("Error while loading file '" + file.url + "': " + file.errorMessage);
                }

            });

            this.load.onLoadComplete.add(() => {
                if(this.retryFiles.length > 0) {
                    
                    if(this.retryCounter > 3) {
                        throw Error("Retry limit for downloads exceeded");
                    }    

                    // setTimeout is neccessary to break Phaser's loader finishedLoading reset (happens AFTER onLoadComplete dispatch...)
                    setTimeout(() => {
                        this.load.reset();
                        let urlPath = 'img/standard/' + this.game.parlay.viewType + '/' + this.game.parlay.viewDpi + 'x/';

                        _.each(this.retryFiles, (fileData) => {
                            console.log('downloading standard theme files for ', fileData.key, fileData);

                            let splitUrl = fileData.data.url.split("/");
                            let fileName = splitUrl[splitUrl.length - 1];

                            switch(fileData.data.type) {
                                case 'textureatlas':
                                    let fileNameParts = fileName.split('.');
                                    this.load.atlasJSONHash(fileData.key, revManifest.getURL(urlPath + fileNameParts[0] + '.png'), revManifest.getURL(urlPath + fileNameParts[0] + '.json'));
                                    break;

                                case 'image':
                                    this.load.image(fileData.key, revManifest.getURL(urlPath + fileName));
                                    break;
                            }
                        });

                        this.retryFiles = [];
                        this.retryCounter++;
                        this.load.start();
                    }, 10);

                } else {
                    this._create();
                }
            });

            // wait for js to load then start preloader
                this.retryCounter++;
                this.load.start();
        };

        viewTypeScript.onload = function () {
            setPreloadData();
        };
    },

    // not the real phaser "create" but my own override
    _create: function() {
        console.log('bootstrap, create');

        if (this.game.parlay.viewType === 'compact') {
            // Force portrait orientation
            this.scale.forcePortrait = true;
        }

        this.scale.scaleMode                = Phaser.ScaleManager.SHOW_ALL;
        this.scale.fullScreenScaleMode      = Phaser.ScaleManager.SHOW_ALL;
        this.scale.pageAlignHorizontally    = true;
        this.scale.pageAlignVertically      = true;
        this.stage.disableVisibilityChange  = true;

        this.game.parlay.postBootstrap();

        this.game.state.add('preloader', PhaserNS.Preloader);
        this.game.state.add('game',      PhaserNS.Game);

        // onFileError does not get cleared when moving to next state, can get caught up on audio files like 80ball
        this.load.onLoadComplete.removeAll();
        this.load.onFileError.removeAll();

        this.state.start('preloader', true, false);
    }
};
