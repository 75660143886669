class ScrollComponent {
    constructor(name, component, x, y, scrollMaskHeight, scrollAreaGroup, parentGroup, lineHeight, scrollSprite, themeDefaultOptions) {
        this.name = name;
        this.comp = component;
        this.parentGroup = parentGroup || this.comp.group;

        this.alwaysShowScroll = false;
        this._lockToBottom = false;
        this._manuallyUnlocked = false;

        this.scrollBarGroup = this.comp.game.add.group(this.parentGroup);
        this.scrollLocation = 0;
        this.scrollAreaGroup = scrollAreaGroup;
        this.scrollMaskHeight = scrollMaskHeight * BaseComponent.getScaleForDPI();

        // need to save orig offset to re-apply after scroll;
        this.origScrollAreaGroupY = scrollAreaGroup.y;

        if (lineHeight === undefined) {
            lineHeight = scrollMaskHeight;
        }
        this.lineHeight = lineHeight * BaseComponent.getScaleForDPI();

        this.scrollBarGroup.x = x * BaseComponent.getScaleForDPI();
        this.scrollBarGroup.y = y * BaseComponent.getScaleForDPI();

        this.handle = this.comp.createSprite(0, 0, (scrollSprite) ? scrollSprite : "Scroll_Handle.png");
        let bounds = new Phaser.Rectangle(this.scrollBarGroup.worldPosition.x, this.scrollBarGroup.worldPosition.y, this.handle.width, this.lineHeight);

        this.handle.inputEnabled = true;
        this.handle.input.boundsRect = bounds;
        this.handle.input.useHandCursor = true;
        this.handle.input.enableDrag();
        this.handle.input.allowHorizontalDrag = false;


        let td = this.comp.theme.find(this.name);
        let getValue = (propName, defaultVal) => {
            if (td.prop(propName)) {
                return td.prop(propName);

            } else if (themeDefaultOptions !== undefined && themeDefaultOptions[propName] !== undefined && themeDefaultOptions[propName]) {
                return themeDefaultOptions[propName];

            } else {
                return defaultVal;
            }
        };

        this.scrollBarLine = this.comp.createGraphics(this.handle.width / BaseComponent.getScaleForDPI() / 2, 0, this.scrollBarGroup);
        this.scrollBarLine.lineStyle(getValue('thickness', 4), getValue('color', "0x3054D0"));
        this.scrollBarLine.lineTo(0, this.lineHeight - 1);

        this.scrollBarGroup.add(this.handle);

        this.handle.events.onDragUpdate.add((sprite) => {
            if(Number.isNaN(sprite.y)) {
                sprite.y = 0;
            }

            this.scrollLocation = (sprite.y / (this.lineHeight - sprite.height));
            // console.log(scrollAreaGroup.y, scrollAreaGroup.height, this.scrollMaskHeight, this.scrollLocation);

            scrollAreaGroup.y = -((scrollAreaGroup.height - this.scrollMaskHeight) * this.scrollLocation) + this.origScrollAreaGroupY;
            this._manuallyUnlocked = (this.scrollLocation !== 1);
        });

        this.testShowScrollBar();

        // console.log('scrollAreaGroup', scrollAreaGroup.width, scrollAreaGroup.height);
        //
        // var dragZone = game.add.sprite(0, 0, 'asdf');
        // // scrollAreaGroup.add(dragZone);
        // dragZone.width = scrollAreaGroup.width;
        // dragZone.height = scrollAreaGroup.height;
        //
        // console.log('scrollAreaGroup.worldPosition', scrollAreaGroup.worldPosition);
        //
        // dragZone.y = this.y;
        // dragZone.x = this.x;
        //
        // dragZone.inputEnabled = true;
        // // dragZone.input.useHandCursor = true;
        // dragZone.input.enableDrag();
        // dragZone.input.allowHorizontalDrag = false;
        //
        // dragZone.events.onDragUpdate.add(function(sprite) {
        //     console.log('onDragUpdate', scrollAreaGroup.worldPosition.y, sprite.worldPosition.y);
        //     scrollAreaGroup.y = sprite.y;
        // }, this);

        return this;
    }

    lockToBottom(lockToBottom) {
        this._lockToBottom = !!lockToBottom;
    }

    alwaysVisible(alwaysVisible) {
        this.alwaysShowScroll = !!alwaysVisible;
        this.scrollBarGroup.visible = !!alwaysVisible;
    }

    testShowScrollBar() {
        if(this.scrollAreaGroup.height > this.scrollMaskHeight) {
            this.handle.inputEnabled = true;
            this.handle.y = this.scrollLocation * (((this.lineHeight - this.handle.height)) - (this.handle.height/2));

        } else {
            this.handle.inputEnabled = false;
        }

        if(!this.alwaysShowScroll) {
            this.scrollBarGroup.visible = this.scrollAreaGroup.height > this.scrollMaskHeight;
        }

        if(!this._manuallyUnlocked && this._lockToBottom && (this.scrollAreaGroup.height > this.scrollMaskHeight)) {
            this.handle.y = this.lineHeight - this.handle.height;
            this.scrollLocation = 1;
            this.scrollAreaGroup.y = -(this.scrollAreaGroup.height - this.scrollMaskHeight);
            this.scrollAreaGroup.y += this.origScrollAreaGroupY;
        }
    }

    appearance(handleSprite) {
        if(handleSprite) {
            this.handle.frameName = handleSprite;
        }
    }
}