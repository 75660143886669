class BaseCardStrip extends BaseComponent {
    constructor(gameInstance, x, y, baseGroup, id, numbers, w, h, cardHeight, cardType, autoDaub) {
        super(gameInstance, x, y, baseGroup);

        this.autoDaub = autoDaub;
        // this.stripId = stripId

        this.cardTypesProxy = {
            Card75,
            Card80,
            Card90
        };

        this.w = w;
        this.h = h;
        this.x = x;
        this.y = y;

        this.cardHeight = cardHeight;
        this.cardType = cardType;

        this.yOffset = 22;

        this.togo = 99;
        this.cards = [];

        // Note the priority flag on this signal, it's important that all cards have higher priority than 'CardDeck'
        this.signalBinding_ballCall = this.gameInstance.signal_ballCall.add((bc) => {
            this.togo = 0;
            _.each(this.cards, (c) => {
                if (c.togo <= 3) {
                    this.togo += c.togo;
                }
            });
            this.draw();
        }, this, 1);

        const stripBack = this.createNineSlice(0, 0, 'StripsBack.png', 10, this.w, this.h);
        let txt = this.createText('BaseCardStrip_ID_Value', this.w / 2, 0, "Strip " + id, {
                fontSize: 18,
                fill: "#535353"
            },
            BaseComponent.TXT_ANCHOR_CENTER
        );

        let yyy = 0;

        _.each(numbers, function (numbers, cardId) {
            const cardStrip = new this.cardTypesProxy[this.cardType](
                this.gameInstance,
                1,
                (this.yOffset + (this.cardHeight * yyy++)) * BaseComponent.getScaleForDPI(),
                cardId,
                numbers,
                this.group,
                this.autoDaub
            )

            this.cards.push(cardStrip);
        }, this);

        // this.createOverlay()
    }

    destroy() {
        this.signalBinding_ballCall.detach();
        super.destroy();
    }

    draw() {
        this.cards = _.sortBy(this.cards, 'togo');

        let yyy = 0;
        _.each(this.cards, (card) => {
            card.group.y = (this.yOffset + (this.cardHeight * yyy++)) * BaseComponent.getScaleForDPI();
        });
    }

    createOverlay() {
        let overlay = this.game.add.graphics(0, 0);
        let td = this.theme.find('BaseModal_Overlay');
        overlay.beginFill(td.prop('fill', 0x000000), td.prop('opacity', 0.7));
        overlay.drawRect(0, 0, 300, 900);
        overlay.endFill();

        this.group.add(overlay);
    }

    calculateTogo() {
        if (this.autoDaub) {
            _.each(this.cards, (card) => {
                card.calculateTogo();
            });
        }

        this.draw();
    }
}
