var PhaserNS = PhaserNS || {};

PhaserNS.Game = function(game) {
};

PhaserNS.Game.prototype = {

    init: function() {
        this.modal = null;
        this.game.scrollDown  = false;
        this.game.scrollUp = false;
        // Fix: Disable mouse events on Android touch-enabled devices - This is fixed on Phaser-CE v2.11.0 @Roberto
        this.game.input.maxPointers = 1;
        if (this.game.device.touch) {
            this.game.input.mouse.stop();
        }
        // Fix blur for Moto G 2nd Gen V5
        if (this.game.device.android && window.innerWidth === 598 && window.innerHeight === 279 &&
            window.screen.width === 640 && window.screen.height === 360) {
            this.game.renderer.renderSession.roundPixels = true;
            this.game.stage.smoothed = false;
            this.game.renderer.imageSmoothingEnabled = false;
            this.game.context.imageSmoothingEnabled = false;
            this.game.antialias = false;
        }
        // End of fix
    },

    preload: function() {
        console.log('game, preload');
        this.stage.backgroundColor = '#000';
        this.stage.disableVisibilityChange = true;

        game.plugins.add(PhaserNineSlice.Plugin);

        const bgImg = this.add.image(0, 0, 'bg');

        // if portrait view, adjust height to fill in bottom based on device height
        if (this.game.parlay.isCompactView()) {
            bgImg.height = this.game.world.height;
            bgImg.scale.x = bgImg.scale.y;
        }
},

    create: function() {
        console.log('game, create');
        this.game.parlay.postInit();
    },

    update: function() {
        if(this.game.scrollDown) {
            this.game.currScrollComp.scrollDown();
        }
        else if(this.game.scrollUp) {
            this.game.currScrollComp.scrollUp();
        }
    }
};