class Tournament {
    constructor(core) {
        this.core = core;
        this.signal_tournamentLeaderboard = new Phaser.Signal();

        this.core.socket.on('tournament:leaderBoard', (resp) => {
            if(resp.success) {
                this.signal_tournamentLeaderboard.dispatch(resp.data);
            } else {
                this.core.openMessageModal(this.core.i18n._t(resp.msg));
            }
        });
    }

    requestLeaderBoardData() {
        this.core.socket.emit('tournament:leaderBoard', {
            game_id: ParlayBingo.queryString("GAMEID")
        });
    }
}